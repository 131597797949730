import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import {useEffect} from "react";
import {IconButton} from "@mui/material";
import CloseIcon from "../../assets/svg/CloseIcon";

const Fade = React.forwardRef(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    maxWidth: 850,
    height: "88vh", // Set a fixed height
    overflowY: "auto", // Enable vertical scrolling
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    boxShadow: 24,
    p: 2,
};

export default function ModalBase() {
    const [open, setOpen] = React.useState(false);
    const handleModal = () => {
        setOpen(!open);
    };

    useEffect(() => {
        handleModal()
    }, [])

    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className={"radius8"}>
                        <div className={"d-flex justify-content-end"}>
                            <IconButton style={{marginTop: "-5px", marginRight: "-5px"}} onClick={handleModal}>
                                <CloseIcon color={"#000"} />
                            </IconButton>
                        </div>
                        <div className={"text-center px-sm-4"}>
                            <h4 className={"mb-4 bold"} style={{fontWeight: "bold"}}>
                                Redirecționează 3,5% din impozitul pe venit
                            </h4>
                            <p className="font16 mb-3">
                                Asociația Creștină de caritate Gosen Arad, este o organizație non-profit care are un serviciu social acreditat ,,<b>Centru de zi și recuperare pentru copii cu dizabilități Gosen Arad</b>''. Serviciul este destinat copiiilor cu autism și Sindrom Down
                            </p>
                            <p className="font16 mb-4">
                                Ne bucurăm să te avem aici, în comunitatea oamenilor cu spirit civic. <b>Împreună suntem mai buni</b>, mai puternici și mai curajosi. <b>Împreună putem aduce o schimbare în bine</b> în societatea noastră, de aceea avem nevoie de cât mai mulți oameni ca tine.
                            </p>
                            {/* <a href={"https://redirectioneaza.ro/centrulgosen"} target="_blank">
                                <Button className={"mb-3"} style={{borderRadius: "20px"}} onClick={handleModal} variant="contained"  color="success" size="large">
                                    Redirectionează 3,5%
                                </Button>
                            </a> */}
                        </div>
                        <div className="f230ro-formular"></div>

                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}