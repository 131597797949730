import React, {useEffect, useState} from "react";
import "./ListaCampanii.scss";
import Campaign from "./Campanie/Campaign";

// interface State {
//
// }

export default function ListaCampanii(props) {
    return (
        <div id={"ListaCampanii"} style={{ paddingTop: "80px", minHeight: "calc(100vh - 105px)" }}>
            <div className={"container py-5"}>
                <div className={"row py-5"}>
                    <div className={"col-xs-12"}>
                        <h1 className={"text-center"}>Fiecare copil este o campanie organizată de Centru Gosen</h1>
                    </div>
                </div>
                <Campaign></Campaign>
                <Campaign></Campaign>
                <Campaign></Campaign>
            </div>
        </div>
    );
}


