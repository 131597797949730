import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import "./CheckoutForm.scss";
import {FormControlLabel, Radio, RadioGroup, Button} from "@mui/material";
// import Radio from '@mui/joy/Radio';
// import RadioGroup from '@mui/joy/RadioGroup';
import FormattedInput from "../FormattedInput/FormattedInput";
import TextField from "@mui/material/TextField";

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const { amount, setAmount } = props;

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [justify, setJustify] = useState('15');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [comment, setComment] = useState('')

    useEffect(() => {
        setAmount(parseInt(justify));
    }, [justify]);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Donatia a fost facuta cu success!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "https://www.centrulgosen.com/multumesc",
                payment_method_data: {
                    billing_details: {
                        name: `${firstName} ${lastName}`,
                        email: email,
                        phone: phone,
                    },
                },
                receipt_email: email,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error || error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <div className={'payment-form-sum-select'}>
                <h1 style={{marginBottom: "24px", textAlign: "center"}}>Împreună schimbăm destine!</h1>
                <FormattedInput numberformat={justify} setNumberformat={setJustify} />
                <RadioGroup
                    className={'payment-form-radioGroup row flex-row'}
                    aria-labelledby="segmented-controls-example"
                    name="justify"
                    value={justify}
                    onChange={(event) => setJustify(event.target.value)}
                    sx={{
                        minHeight: 48,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}
                    >
                    {['15', '50', '100', '150'].map((item, index) => (
                        <Button
                        key={index}
                        variant="outlined"
                        onClick={() => setJustify(item)}
                        sx={{
                            p: 2,
                            minWidth: 60,
                            textTransform: 'none',
                            margin: 0,
                            backgroundColor: justify === item ? '#d32f2f' : 'transparent',
                            color: justify === item ? '#ffffff' : 'inherit',
                            borderColor: justify === item ? '#d32f2f' : 'grey',
                            '&:hover': {
                            backgroundColor: justify === item ? '#d32f2f' : '#f0f0f0',
                            color: justify === item ? '#ffffff' : 'inherit',
                            },
                        }}
                        >
                        {item}
                        </Button>
                    ))}
                </RadioGroup>
            </div>
            <hr/>
            <h3 style={{marginBottom: "24px"}}>Informatii personale</h3>
            <div style={{display: "flex", gap: "24px"}}>
                <TextField
                    required
                    fullWidth
                    id="last_name"
                    label="Nume"
                    variant="outlined"
                    size="small"
                    value={lastName}
                    onChange={event => setLastName(event.target.value)}
                />
                <TextField
                    required
                    fullWidth
                    id="first_name"
                    label="Prenume"
                    variant="outlined"
                    size="small"
                    value={firstName}
                    onChange={event => setFirstName(event.target.value)}
                />
            </div>
            <TextField
                required
                fullWidth
                id="email"
                type={'email'}
                label="Adresa de Email"
                placeholder={"Adresa de Email"}
                variant="outlined"
                size="small"
                helperText="Vom trimite chitanța de donație la această adresă."
                value={email}
                onChange={event => setEmail(event.target.value)}
            />
            <TextField
                fullWidth
                id="phone"
                label="Numar Telefon"
                variant="outlined"
                size="small"
                helperText="Numărul de telefon ne va ajuta să avem o mai bună legătura cu dumneavoastră."
                value={phone}
                onChange={event => setPhone(event.target.value)}
            />
            <TextField
                fullWidth
                id="message"
                label="Comentarii"
                placeholder={"Lasă un comentariu"}
                variant="outlined"
                size="small"
                helperText="Introdu un comentariu în legătura cu donația dumneavoastră."
                value={comment}
                onChange={event => setComment(event.target.value)}
            />
            <hr/>
            <h3 style={{marginBottom: "24px"}}>Informatii despre card</h3>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button className="payment-stripe-button-text" disabled={isLoading || !stripe || !elements} id="submit">
                <span>
                  {isLoading ? <div className="spinner" id="spinner"></div> : `Donează ${ parseFloat(justify).toLocaleString()} RON`}
                </span>
            </button>
             {/* Show any error or success messages  */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}