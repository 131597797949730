import React from "react";
import "./Doneaza.scss";
// Sections
import bgheader from "../assets/img/pages/donation/joel_overbeck.jpg";
import CheckMark from "../assets/svg/Checkmark";
import {Button} from "@mui/material";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {NavLink} from "react-router-dom";
import logo from "../assets/img/logo.svg";
import { Link } from "react-scroll";

export default function Multumesc() {
    return (
        <div id="home" className={'multumescContent'} style={{paddingTop: "80px"}}>
            <div className={'despreAutismHeader'} style={{backgroundImage: `url("${bgheader}")`}}></div>
            <div className="container" style={{ height: "calc(100vh - 185px)" }}>
                <div className="d-flex justify-content-center align-items-center flex-column py-5">
                    <a className="flexCenter animate pointer my-5" href="/">
                        <img src={logo} alt={logo} width={"145px"} height={"145px"}/>
                    </a>
                    <h1 className="font40 extraBold text-center" style={{marginBottom: "64px"}} >Îți mulțumim pentru donația făcută.</h1>
                </div>
            </div>
        </div>
    );
}


